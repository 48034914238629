import { GetServerSidePropsResult, GetStaticProps } from 'next';
import { addApolloState, APOLLO_STATE_PROP_NAME } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import initApolloClient from '../initApolloClient';
import { gql, NormalizedCacheObject } from '@apollo/client';
import {
    BreadcrumbFragment,
    FooterItemsFragment,
    GlobalDocument,
    GlobalQuery,
    GlobalQueryVariables,
    MenuItemsFragment,
    NotificationFragment,
} from '../generated/graphql';
import { defaultLanguage } from '../pages/_app';
import filterRecursive from './filterRecursive';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import merge from 'lodash.merge';

export const query = gql`
    query Global($lang: String) {
        ...GlobalQuery
    }
`;

export const fragment = gql`
    fragment GlobalQuery on Query {
        menu: entries(level: 1, site: [$lang], section: "menu") {
            ...MenuItems
        }

        breadcrumb: entries(level: 1, site: [$lang], section: "pages") {
            ...Breadcrumb
        }

        footer: entries(level: 1, site: [$lang], section: "footer") {
            ...FooterItems
        }

        notification: globalSet(handle: "notification", site: [$lang]) {
            ...Notification
        }

        search: entry(type: "search", site: [$lang]) {
            uri
        }
    }

    fragment Breadcrumb on pages_page_Entry {
        id
        title
        ...MenuItemPageFields
        ...MenuItemPageRecursive
    }
`;

export type PropsResult = GetServerSidePropsResult<{
    [APOLLO_STATE_PROP_NAME]?: NormalizedCacheObject;
    [k: string]: unknown;
}>;

// need to do this manually because craft ignores parameters passed to children 🙃
const removeHiddenMenuItemsFromData = (data: GlobalQuery): GlobalQuery['menu'] =>
    (data.menu?.map(item => {
        if (item?.__typename === 'menu_menu_Entry' && item.menuLink?.element?.__typename === 'pages_page_Entry') {
            // I'm very sorry, but trying to make the craft types make sense to TS is a futile effort.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const children = filterRecursive((item.menuLink.element.children as any) ?? [], 'settingsMenuHidden');
            return {
                ...item,
                menuLink: {
                    ...item.menuLink,
                    element: {
                        ...item.menuLink.element,
                        children,
                    },
                },
            };
        }
        return item;
    }) as GlobalQuery['menu']) ?? [];

export interface GlobalProps {
    footer?: FooterItemsFragment[];
    menu?: MenuItemsFragment[];
    breadcrumb?: BreadcrumbFragment[];
    notification?: Maybe<NotificationFragment>;
    searchLink?: Maybe<string>;
}

export const addGlobalProps = <T extends GlobalQuery>(data: T): GlobalProps => {
    const items = removeHiddenMenuItemsFromData(data);
    return {
        footer: data?.footer ? (data.footer as FooterItemsFragment[]) : [],
        menu: items ? (items as MenuItemsFragment[]) : [],
        breadcrumb: data.breadcrumb ? (data.breadcrumb as BreadcrumbFragment[]) : [],
        notification: data?.notification ? (data.notification as NotificationFragment) : null,
        searchLink: data?.search?.uri ?? null,
    };
};

// Local cache for the global query. Per SSR instance, it will keep the global query in memory for x minutes.
// const CACHE_EXPIRATION_TIME = 10 * 60 * 1000;
type QueryCache = { [lang: string]: { props?: PropsResult; timestamp: number } };
const globalQueryCache: QueryCache = {};
// const isCached = (lang: string): boolean =>
//     !!globalQueryCache[lang]?.props && Date.now() - globalQueryCache[lang].timestamp < CACHE_EXPIRATION_TIME;
const isCached = (lang: string): boolean => !!globalQueryCache[lang]?.props;
const fromCache = (lang: string): PropsResult | void => (isCached(lang) ? globalQueryCache[lang].props : undefined);
const addToCache = (lang: string, props: PropsResult) => {
    globalQueryCache[lang] = {
        props,
        timestamp: Date.now(),
    };
};

// use this utility function to add the global props (menu, footer) to existing props.
const addGlobalQuery = async (propsResult: PropsResult = { props: {} }, locale?: string): Promise<PropsResult> => {
    if (locale === 'default') return { props: {} };

    const props = 'props' in propsResult ? await propsResult.props : {};

    // no props, so probably redirect or notFound - no need for globalprops anymore.
    if (!('props' in propsResult)) {
        return propsResult;
    }

    // start a new client
    const localeParam = locale ?? defaultLanguage;
    const client = initApolloClient(localeParam);

    let result: PropsResult;
    const cachedProps = fromCache(localeParam);
    if (cachedProps) {
        result = cachedProps;
    } else {
        const { data } = await client.query<GlobalQuery, GlobalQueryVariables>({
            query: GlobalDocument,
            variables: {
                lang: localeParam,
            },
        });

        // make sure to add the apollo state to the query cache, so we can include that in the return next time
        result = addApolloState(client, {
            props: {
                ...addGlobalProps(data),
            },
        });
        addToCache(localeParam, result);
    }

    return {
        props: merge(props, 'props' in result ? result.props : {}),
    };
};

// simple wrappers for situations where only the global props are needed and nothing more
export const getGlobalProps: GetStaticProps = context => addGlobalQuery({ props: {} }, context.locale);

export default addGlobalQuery;
