import styled from 'styled-components';
import { BaseImgProps } from '@oberoninternal/travelbase-website/dist/components/Img';
import Hero from '@oberoninternal/travelbase-website/dist/components/Hero';
import focalPointToPosition from '../utils/focalPointToPosition';

export interface HeroProps extends BaseImgProps {
    ratio: number;
    mobileRatio: number;
    focalPoint?: number[];
}

export default styled(Hero)<HeroProps>`
    overflow: hidden;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        &:before {
            padding-top: ${({ mobileRatio }) => Math.round(mobileRatio * 100)}%;
        }
    }

    img {
        object-position: ${({ focalPoint }) => focalPointToPosition(focalPoint)};
    }
`;
