import React, { FC } from 'react';
import Link from './Link';
import Img from '@oberoninternal/travelbase-website/dist/components/Img';
import { Box } from '@rebass/grid';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import styled from 'styled-components';
import { CardFragment, TileTransformFragment, ListImageFragment } from '../generated/graphql';
import { gql } from '@apollo/client';
import Placeholder from './icons/Placeholder';
import { tileTransformToProps } from '../constants/imageTransforms';
import focalPointToPosition from '../utils/focalPointToPosition';
import createImgProps from '@oberoninternal/travelbase-website/dist/utils/createImgProps';

interface Props {
    item: CardFragment;
    dontPrefixLocale?: boolean;
}

export const fragment = gql`
    fragment Card on EntryInterface {
        title
        uri
        ...PageCard
        ...NewsCard
        ...ArticleCard
        ...HomeCard
        ...ContactCard
        ...AgendaCard
        ...ThankYouCard
        ...CompanyCard
        ...MyTexelCard
    }

    fragment CardImage on AssetInterface {
        ...TileTransform
    }

    fragment PageCard on pages_page_Entry {
        id
        settingsMetaDescription
        settingsNotSearchable
        heroImage {
            ...CardImage
        }
    }

    fragment ArticleCard on articles_article_Entry {
        postDate @formatDateTime(format: "j F Y", locale: $lang)
        settingsMetaDescription
        heroImage: newsHeroImage {
            ...CardImage
        }
    }

    fragment NewsCard on news_news_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }

    fragment HomeCard on homepage_homepage_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }

    fragment ContactCard on contact_contact_Entry {
        settingsMetaDescription
        heroImage {
            ...CardImage
        }
    }

    fragment AgendaCard on agenda_agenda_Entry {
        settingsMetaDescription
    }

    fragment ThankYouCard on thankYou_thankYou_Entry {
        settingsMetaDescription
    }

    fragment CompanyCard on companies_companies_Entry {
        settingsMetaDescription
    }

    fragment MyTexelCard on myTexel_myTexel_Entry {
        settingsMetaDescription
    }
`;

interface CardItemProps {
    uri?: string | null;
    img?: TileTransformFragment | ListImageFragment | null;
    webpListImage?: ListImageFragment | null;
    title?: string | null;
    metaDescription?: string | null;
    dontPrefixLocale?: boolean;
}

export const CardItem: FC<React.PropsWithChildren<CardItemProps>> = ({
    uri,
    img,
    webpListImage,
    title,
    metaDescription,
    dontPrefixLocale = false,
    ...rest
}) => (
    <Item {...rest}>
        <Link
            href={uri?.startsWith('/') ? uri : (uri && `/${uri}`) ?? ''}
            locale={dontPrefixLocale ? false : undefined}
        >
            <a>
                <ImgWrapper hasImage={!!img}>
                    {img && img.__typename === 'library_Asset' && (
                        <StyledImg loading="lazy" {...tileTransformToProps(img)} />
                    )}
                    {img && webpListImage && img.__typename === 'ImageTransform' && (
                        <StyledImg loading="lazy" {...createImgProps(img, webpListImage)} />
                    )}

                    {!img && <Placeholder />}
                </ImgWrapper>

                <Box pt={4}>
                    <Title variant={'small'} elementType={'h3'}>
                        {title}
                    </Title>
                    {metaDescription && <LineLimitBody variant={'small'}>{metaDescription}</LineLimitBody>}
                </Box>
            </a>
        </Link>
    </Item>
);

const Card: FC<React.PropsWithChildren<Props>> = ({ item, dontPrefixLocale = false }) => {
    switch (item.__typename) {
        case 'articles_article_Entry':
        case 'pages_page_Entry':
        case 'news_news_Entry':
        case 'homepage_homepage_Entry': {
            const img = item.heroImage?.[0];
            return (
                <CardItem
                    uri={item.uri}
                    img={img}
                    title={item.title}
                    metaDescription={item.settingsMetaDescription}
                    dontPrefixLocale={dontPrefixLocale}
                />
            );
        }
        case 'myTexel_myTexel_Entry':
        case 'agenda_agenda_Entry':
        case 'companies_companies_Entry':
        case 'thankYou_thankYou_Entry':
        case 'contact_contact_Entry': {
            return (
                <CardItem
                    uri={item.uri}
                    title={item.title}
                    metaDescription={item.settingsMetaDescription}
                    dontPrefixLocale={dontPrefixLocale}
                />
            );
        }
        default:
            // eslint-disable-next-line no-console
            console.warn(`Card: unsupported type ${item.__typename}`);
            return null;
    }
};

export default Card;

const StyledImg = styled(Img)<{ focalPoint?: number[] }>`
    object-position: ${({ focalPoint }) => focalPointToPosition(focalPoint)};
`;

const Item = styled.div`
    cursor: pointer;
    margin-bottom: ${({ theme }) => theme.spacing['70_XLarge']};
    min-width: 0;

    h3 {
        margin-bottom: ${({ theme }) => theme.spacing['20_Tiny']};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        &:hover {
            color: ${({ theme }) => theme.colors.primary['60']};
            text-decoration: underline;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex: 3;
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin-bottom: ${({ theme }) => theme.spacing['80_XXLarge']};
    }

    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.primary['80']};
    }
`;

const ImgWrapper = styled.div<{ hasImage?: boolean }>`
    position: relative;

    ${({ hasImage, theme }) =>
        !hasImage &&
        `   width: 100%;
            background: ${theme.colors.primary['10']};

            svg {
                height: 100%;
                width: 100%;
                top: 0;
                position: absolute;
            }
    `};

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 75%;
    }

    img {
        object-fit: cover;
        object-position: center center;
    }
`;

const LineLimitBody = styled(Body)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;
